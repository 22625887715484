.footer-bg{
    background-color: #262E38;
}
.footer-link span{
    color: #B5B5B5 !important;
    cursor: pointer;
}
.footer-bottom{
    font-size: 13px;
    font-weight:400;
    color : #B5B5B5;
    text-align: center;
}