.centerContent {
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.homeBanner {
    height: 500px;
    // background-image: url(../../../assets//images/home_background.png), linear-gradient(red, yellow);
    background: linear-gradient(268.06deg, rgba(255, 255, 255, 0.81) 19.2%, rgba(255, 198, 0, 0) 105.86%), url(../../../assets//images/home_background.png);
    background-size: 100% auto;
    background-repeat: no-repeat;

    .MuiGrid-container {
        margin-bottom: 40px;

        .MuiGrid-item {
            padding: 10px 20px;
        }
    }
}

.verticalCenterImage {
    position: relative;
}

.homeUpdateButton {
    position: absolute !important;
    transform: translate(-50%, -50%);
    top: 80%;
    left: 50%;
}

.testimonialHome {

    .thumbs-wrapper,
    .carousel-status {
        display: none;
    }

    .carousel-body {
        height: 350px;
        display: flex;
        align-items: center;
        justify-content: center;

        .testimonialUser {
            display: block;
            text-align: left;
            display: flex;
            align-items: center;
            justify-content: center;

            .profileImage {
                width: 80px !important;
                height: 80px !important;
                border-radius: 50%;
            }
        }
    }

    .carousel .control-next.control-arrow:before {
        border-left: 8px solid #100e0e;
    }

    .carousel .control-prev.control-arrow:before {
        border-right: 8px solid #100e0e;
    }

    .carousel.carousel-slider .control-arrow:hover {
        background: none;
    }
}

.homeContactUs {
    height: 500px;
    background-color: #ddd6d6;

    .inputArea {
        .MuiGrid-item {
            padding: 10px;

            input {
                background-color: #ffffff;
            }
        }
    }
}

.homeAboutUs {
    background-color: #601B10;
    justify-content: flex-start;
}



.buttonIcon {
    img {
        max-height: 30px;
        margin-right: 10px;
    }
}

.panel-bar {
    border: 1px solid #FBE1F8;
    min-height: 280px;
    border-radius: 12px;
    // max-width: 383px;
    // min-width:290px;
    padding: 25px 25px;
    background: linear-gradient(180deg, #FFFCC7 0%, #FFFFFA 37.43%);
    cursor: pointer;
}

.footerHeader {
    font-weight: 500 !important;
    font-size: 20px !important;
    color: #B5B5B5 !important;
}

.footerList {
    font-size: 12px !important;
    font-weight: 400 !important;
    color: #B5B5B5 !important;
}

.white-brick {
    border-radius: 12px;
    padding: 30px;
    background: #fff;
    box-shadow: 3px 6px 12px 3px rgba(157, 157, 157, 1);

    .tile-header {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
    }

    .tile-content {
        display: flex;
        flex-direction: column;
        text-align: left;

        .MuiTypography-body1 {
            line-height: 24px;
        }
    }

    .tile-btn {
        text-align: right;
    }

}

.cream-tile {
    border: 1px solid #FBE1F8;
    border-radius: 12px;
    padding: 25px;
    background: linear-gradient(180deg, #FFFCC7 0%, #FFFFFA 37.43%);

    .tile-content {
        display: flex;
        flex-direction: column;
        text-align: left;

        .MuiTypography-body1 {
            line-height: 24px;
        }
    }

    .tile-btn {
        text-align: right;
    }
}

.white-tile {
    background-color: #fff;
    border-radius: 12px;
    padding: 30px;
    box-shadow: 0px 4px 11px 0px #9D9D9D40;

    .tile-content {

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: left;

        .rounded-img-box {
            height: 100px;
            width: 100px;
            border-radius: 50%;
            background-color: #DDF1FF;

            img {
                padding: 16px;
            }
        }
    }

    .tile-btn {
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
    }
}

.sectionFiveHeader {
    font-weight: 600 !important;
    padding-top: 40px !important;
    padding-bottom: 10px !important;
    font-size: 18px !important;
    line-height: 22px !important;
}

.sectionFiveBody {
    font-size: 15px !important;
    line-height: 24px !important;
    padding-top: 10px !important;
    padding-bottom: 20px !important;
}

.sectionFour {
    background-color: #fff !important;
    border: 1px solid #DDDDDD !important;
    border-radius: 12px !important;
    padding: 45px !important;
    max-width: 588px !important;
}

.sectionFourHeader {
    font-weight: 600 !important;
    padding-top: 10px !important;
    font-size: 20px !important;
    line-height: 30px !important;
}

.sectionFourBody {
    font-size: 15px !important;
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    line-height: 30px !important;
}

.sectionTwo {
    color: #444444 !important;
    font-size: 17px !important;
    line-height: 30px !important;
}

.sectionThreeHeader {
    font-weight: 600 !important;
    padding-top: 15px !important;
    font-size: 20px !important;
    line-height: 22px !important;
}

.sectionThreeBody {
    font-size: 15px !important;
    line-height: 24px !important;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
    min-height: 96px;
}

.sectionOne {
    padding: 30px 30px !important;
    margin: 58px 58px !important;
}

.sectionOneHeader {
    color: #FFFFFF !important;
    font-weight: 500 !important;
    font-Size: 45px !important;
    line-height: 54px !important;
}

.sectionOneBody {
    font-size: 20px;
    line-height: 31.6px;
    color: #FFFFFF;
    font-weight: 400;
    padding-top: 20px;
    padding-bottom: 40px;
    min-width: 572px;
}

.displayText {
    display: block;
}

.read-more {
    border: 1px solid #D2372F !important;
    color: #9C2A18 !important;
    padding: 4px 12px !important;
    font-size: 12px !important;

}

.enroll-button {
    border-radius: 12px;
    background-color: #FFFCC7 !important;
    color: #861D17 !important;
    padding: 6px 20px !important;
    font-size: 14px !important;
    font-weight: 700 !important;
    line-height: 22.48px !important;
}

// .panel-bar:hover{
//     border: 1px solid #754D2C;
//     border-top: 6px solid #754D2C;
//     padding: 15px 0px;

// }
.aboutLogo img {
    border-radius: 7px;
}

.stock-mover-chart-container {
    .mui-card {
        position: relative;
    }

    .interval-selectors {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-inline: 1rem;
        font-size: small;
        color: #dadcdc;
        font-weight: 600;

        span {
            padding: 0.5rem 1rem;
            border-radius: 8px;
            cursor: pointer;

            &.active {
                background-color: #e2e5f5;
                color: #3e52c1;
                cursor: not-allowed;
            }
        }
    }

    .spinner-container {
        position: absolute;
        inset: 0;
        display: grid;
        place-items: center;
        background-color: #00000033;

        .animate-spin {
            animation: App-logo-spin infinite 1s linear;
        }
    }
}

.latest-stocks-section {
    padding-inline: 2rem;
}

@media only screen and (max-width: 600px) {
    .imageMobile {
        display: none !important;
    }

    .homeBanner {
        background-size: auto !important;
    }

    .homeAboutUs {
        .aboutLogo {
            transform: translate(-50%, -50%);
            left: 50%;
        }

        .MuiGrid-item {
            padding: 15px;
        }
    }

    .odisha1 {
        .centerContent {
            .MuiGrid-item {
                padding-bottom: 40px;
            }
        }
    }
}