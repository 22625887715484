.link-main.selected {
    color: #0CABB8 !important;
    cursor: default;
}

.link-main {
    color: #000 !important;
    font-size: 14px !important;
    font-weight: 500;
    line-height: 18px;
    margin-right: 30px;
    text-decoration: none !important;
    outline: none !important;
    cursor: pointer;
}

.admin-toolbar header {
    padding: 0px !important;
}

.admin-logo {
    width: 220px;
}

.admin-toolbar .do-btn .MuiSvgIcon-root {
    color: #262e38;
}

.admin-toolbar ul {
    background-color: #262e38;
    color: white;
    overflow-y: auto;
    overflow-x: hidden;
}

.admin-toolbar ul .MuiListItemIcon-root {
    color: #FFEEBD;
}

.admin-toolbar ul li:hover {
    background-color: #FFEEBD;
    color: #444444;
}

.admin-toolbar ul li:hover .MuiListItemIcon-root {
    color: white;
}

.admin-toolbar .selected {
    background-color: #0CABB8;
}

.admin-toolbar .selected:hover {
    background-color: #23CDDA;
    color: white;
}

.admin-toolbar .selected:hover .MuiListItemIcon-root {
    color: #FFEEBD;
}

.nested-menu-option {
    position: fixed;
    width: 250px;
    margin-left: 3%;
    margin-top: -40px;
    background: #262e38;
}

.MuiDrawer-root .MuiPaper-root {
    background-color: #262e38;
}

.nested-menu-option .MuiListItemText-root {
    opacity: 1;
}

.nested-menu-option .MuiListItemIcon-root {
    margin-right: 24px;
}

.subscribe-button {
    position: fixed !important;
    right: 16px;
    bottom: 50px;
    z-index: 1000;
}

.call-out-button {
    position: fixed !important;
    right: 16px;
    top: 25%;
    z-index: 1000;
}

.privacy-policy-content ul {
    line-height: 30px;
}